import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import qr from "../../assets/qr.jpeg";

import Compressor from "compressorjs";
import CopyToClipboard from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import { constant } from "../../api/ApiConstant";
import axios from "../../api/axios";
import BackButton from "../../components/BackButton";
import ConfirmModal from "../../components/ConfirmationModal";
import app from "../../firebase";
import {
  removeFailure,
  removeSuccess,
  setFailure,
  setSuccess,
} from "../../store/actions/notify.actions";
import { changePageName } from "../../store/actions/page.actions";
import { selectUser } from "../../store/reducers/auth.reducers";
import "./AddFunds.css";

const contents = [
  { title: "Name", content: "UMAMAHESWARI S" },
  { title: "Bank Name", content: "Union bank of india" },
  { title: "Account No", content: "149910100138920" },
  { title: "IFSC", content: "UBIN0814997" },
  {
    title: "USDT Address",
    content: "0x88310d6F340EA142f5963502fBC89ecf20465951",
  },
];

const AddFunds = () => {
  const [selectedFund, setSelectedFund] = useState(25);
  const [fund, setFund] = useState(0);
  const [image, setImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const [otp, setOtp] = useState("");
  // const [otpSent, setOtpSent] = useState(false);
  const textRef = useRef(null);
  const imageRef = useRef();

  const user = useSelector((state) => selectUser(state));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changePageName("Staking Request"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      handleModal();
      setIsLoading(true);
      dispatch(removeFailure());
      if ((selectedFund === "others" && fund === 0) || fund % 25 !== 0) {
        setIsLoading(false);
        handleModal();
        return dispatch(setFailure("Please enter multiplies of $25"));
      }

      const amount = selectedFund === "others" ? fund : selectedFund;

      if (!image) {
        setIsLoading(false);
        handleModal();
        return dispatch(setFailure("Please add proof image"));
      }

      // if (!otpSent) {
      //   const response = await axios.post(
      //     `${constant.otp.send}/${user.id}`,
      //     {}
      //   );
      //   setOtpSent(response.data);
      //   dispatch(setSuccess("OTP sent to your email. Please check it"));
      //   setTimeout(() => dispatch(removeSuccess()), 1000);
      //   setIsLoading(false);
      //   return;
      // }

      // if (!otp) {
      //   setIsLoading(false);
      //   return dispatch(
      //     setFailure("Please enter corresponding otp to your email")
      //   );
      // }

      // const result = await axios.get(
      //   `${constant.otp.verify}/${user.id}/${otp}`
      // );

      // if (!result.data.data) {
      //   setIsLoading(false);
      //   return dispatch(setFailure("Please enter valid OTP"));
      // }

      new Compressor(image, {
        quality: 0.2,
        resize: "contain",
        success(result) {
          const storage = getStorage(app);
          const storageRef = ref(
            storage,
            `stacking-verification-images/${uuidv4()} - ${new Date()} - ${user.refId
            }`
          );
          const uploadTask = uploadBytesResumable(storageRef, result);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => console.log(error),
            () =>
              getDownloadURL(uploadTask.snapshot.ref).then(
                async (downloadURL) => {
                  if (downloadURL) {
                    axios
                      .post(constant.wallet.stacking, {
                        userId: user.id,
                        amount,
                        proofImageUrl: downloadURL,
                        refId: user.refId,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          dispatch(setSuccess("Stacking Request sent"));
                          setTimeout(() => {
                            dispatch(removeSuccess());
                          }, 3000);
                        }
                        setIsLoading(false);
                        imageRef.current.value = "";
                        // setOtp("");
                        // setOtpSent(false);
                      });
                  }
                }
              )
          );
        },
        error(err) {
          console.log(err.message);
        },
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handleChange = (e) => {
    if (e.target.value < 0 || e.target.value > 10000) return;
    setFund(e.target.value);
  };

  const handleImage = (e) => setImage(e.target.files[0]);

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const copyToClipboard = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy text:', error);
      });
  };

  return (
    <>
      <BackButton />
      <div className="card-container">
        <div>
          <div className="form-list">
            <label className="label">Packages</label>
            <select
              value={selectedFund}
              onChange={(e) => setSelectedFund(e.target.value)}
              className="stacking__select"
            >
              {/*<option value={25}>$25</option>
              <option value={50}>$50</option>
              <option value={75}>$75</option>
              <option value={100}>$100</option>
              <option value={125}>$125</option>
              <option value={150}>$150</option>
              <option value={175}>$175</option>
              <option value={200}>$200</option>
              <option value="others">Others</option>*/}
            </select>
          </div>
          {selectedFund === "others" && (
            <div className="form-list">
              <label className="label">Enter Amount</label>
              <input
                type="number"
                className="stacking__input"
                name="AddFunds"
                min="0"
                max="100000"
                onChange={handleChange}
                value={fund}
              />
            </div>
          )}
          <div className="form-list">
            <label className="label">Proof Image</label>
            <input
              type="file"
              className="stacking__input"
              accept="image/*"
              ref={imageRef}
              onChange={handleImage}
            />
          </div>
          {/* {otpSent && (
            <div className="form-list">
              <label className="label">OTP</label>
              <input
                type="text"
                className="stacking__input"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          )} */}
          <div className="btn_container">
            <button
              className="submit__btn dark__pink"
              disabled={isLoading}
              onClick={handleModal}
            >
              {isLoading ? "Loading" : "Submit"}
            </button>
          </div>
        </div>
        <br />
        <div style={{ textAlign: 'center' }}><img src={qr} height="150px" /></div>
        <div className="addFunds__infoContainer">
          <p className="addFunds__info">
            {contents.map(({ title, content }) => (
              title && title === 'Free Coin Address' &&
              <CopyToClipboard text={content} onCopy={onCopyText} key={content}>
                <span
                  className={`info__text ${title === "Free Coin Address" && "info__text--1"
                    }`}
                >
                  {title} : {content} <FaRegCopy />
                </span>
              </CopyToClipboard>
            ))}
            <div className="ad_lnk" ref={textRef}>0x01AdCdacB42c9cf85df1FddA0966b783e684c157</div>
            <button  className="submit__btn dark__pink" onClick={copyToClipboard}>Copy</button>
          </p>

        </div>
        <p className="notes">
          Note: Staking amount should be multiples in 25 $.
        </p>
        {isCopied && <p className="alert__copied">Copied</p>}
      </div>
      <ConfirmModal visible={isModalOpen} yes={handleSubmit} no={handleModal} />
    </>
  );
};
export default AddFunds;
